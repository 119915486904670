.footer
	background: $c-text-invert-back
	border-top: 1px solid #282828
	padding: 60px 1em
	color: #7c7c7c
	font-family: Calibri, 'Trebuchet MS', sans-serif

	&-inner
		max-width: 1064px
		display: flex
		margin: auto

	&-company
		margin: 20px 0
		font-size: 14px

	&-left
		// flex-grow: 1
		display: flex
		flex-direction: column
		width: 253px

	&-socials
		display: flex
		margin-top: 40px

	&-social
		color: inherit
		text-decoration: none
		white-space: nowrap
		a
			margin-right: 0

		.shape
			width: 25px
			height: 25px

		&:not(:last-child)
			margin-right: 2em
			margin-bottom: 2em

	&-socialText
		margin-left: 11px
		text-transform: uppercase
		line-height: 25px
		vertical-align: middle

	&-right
		flex-grow: 1
		text-align: right
		display: flex
		flex-direction: column
		justify-content: flex-end
		align-items: flex-end
		font-size: 15px

	&-created
		margin-bottom: 8px

	+media(550M)
		&-inner
			flex-direction: column
			align-items: center

		&-left
			align-items: center
			margin-bottom: 3em

		&-right
			align-items: center

		&-socials
			display: flex
			flex-direction: column
