.profileGallery
	display: flex
	flex-wrap: wrap
	justify-content: center

	@supports (display: grid) and (--some: var)
		display: grid
		grid-template-columns: repeat(var(--columns, 6), 1fr)
		grid-gap: 55px 78px
		justify-items: center

	&.view-narrow
		max-width: 1124px
		margin: 42px auto 100px
		grid-gap: 63px 73px
		--columns: 4

	&[data-items="1"]
		display: flex

	@media #{$break992M}
		--columns: 3

	@media #{$break480M}
		--columns: 2

	&.view-narrow
		@media #{$break992M}
			--columns: 3

		@media #{$break768M}
			--columns: 2
			grid-column-gap: 20px

	&.view-contact
		--columns: 1

		+media(400px)
			--columns: 2

.directors
	margin-top: 3rem

	@supports (display: grid) and (--some: var)
		grid-template-columns: repeat(var(--columns, 5), 1fr)

	@media (max-width: 550px)
		--columns: 2
