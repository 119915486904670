.videoModal
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	background-color: rgba(0, 0, 0, 0.75)
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	&-box
		position: relative
		padding: 1rem
		background-color: #fff
		width: 75vw
	&-close
		border: none
		background: transparent
		position: absolute
		top: -1.5rem
		right: -1.5rem
		width: fit-content
		height: fit-content
		cursor: pointer
	&-iFrame
		border: none
		width: 100%
		height: 80vh
		aspect-ratio: 16/9
		&::after
			display: block
			padding-top: (9 / 16) * 100%
			width: 100%
