.position
	margin-bottom: 70px

	&:last-child
		margin-bottom: 0

	&-title
		text-align: left
		text-transform: uppercase
		color: #1c1c1c
		font-weight: 700
		letter-spacing: 0.25px

	&-wrapper
		display: flex
		flex-direction: column
		align-items: center
		margin: 42px auto 4rem

.Kitchen
	display: flex
	flex-direction: column
	align-items: center
