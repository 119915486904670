.btn
	padding: 10px 0
	width: 230px
	color: #1c1c1c
	font-size: 16px
	font-weight: 700
	letter-spacing: 0.14px
	border: 2px solid #1c1c1c
	text-decoration: none
	text-align: center

	&-container
		display: flex
		padding: 70px 0
		justify-content: center

	&.view-dark
		background: black
		color: #e9e9e9

	&.view-white
		background: white
		color: #1c1c1c

