.place
	text-align: center
	+media(700px)
		text-align: left
	width: 100%
	max-width: 300px
	margin: 0 auto

	&-outerContainer
		margin: 0 auto
		border-bottom: 2px solid #dcdcdc

	&-container
		display: flex
		flex-wrap: wrap
		justify-content: center
		margin: 40px auto 50px
		max-width: 1023px

		@supports (display: grid) and (--some: var)
			display: grid
			grid-template-columns: repeat(var(--columns, 4), 1fr)
			grid-gap: 80px 200px

	&-name
		color: #1c1c1c
		font-size: 28px
		font-weight: 700
		letter-spacing: 2.48px
		margin: 20px 0 9px
		text-transform: uppercase

	&-info
		color: #626262
		font-size: 19px
		letter-spacing: 0.52px
		line-height: 1.42

	&-contacts
		margin-top: 23px
		text-transform: uppercase
		font-size: 19px
		letter-spacing: 0.52px
		line-height: 1.58
		color: #626262

	&-contactLabel
		color: #161616
		font-weight: bold
		padding-right: 0.3rem

	&-legal
		margin-top: 1em
		font-size: 14px
		line-height: 1.3
		color: #626262

	&-container
		@media (max-width: 1200px)
			grid-column-gap: 33px

		@media #{$break768M}
			--columns: 1
