.front
	height: 80vh
	// background: rebeccapurple
	display: flex
	flex-direction: column
	position: relative
	text-align: center
	z-index: -1

	&-content
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		flex-grow: 1
		margin-top: 107px
		padding: 0 1em

	&-logo
		max-width: 100%

	&-tagline
		font-size: 30px
		color: $c-text-invert
		letter-spacing: 0.47px
		margin: 10px

	&-background
		+absolute(0)
		z-index: -1
		+size(100%)
		object-fit: cover

	&-chevrons
		display: flex
		flex-direction: column
		align-items: center
		padding: 15px
		transition: all 300ms ease-out
		opacity: .5
		margin: 0 auto 15px

		&:hover
			transform: translateY(1em)
			opacity: 1

	&-chevron
		margin: 0

		&:nth-child(1)
			opacity: 0.26
		&:nth-child(2)
			opacity: 0.70
