.workWith
	border-top: 1px solid #e8e8e8
	background-color: #f6f6f6
	display: flex
	flex-direction: column
	align-items: center
	text-align: center
	padding: 111px 1em 0

	&.view-singlePage
		background: white
		border: none
		padding-top: 72px
		padding-bottom: 50px

	&.tabletop
		padding-bottom: 1rem

.kitchen
	width: 100vw
	margin-bottom: -50px

.view-hidden
	display: none
