.videoPreview
	&-container
		display: flex
		flex-wrap: wrap
		justify-content: center

		&.view-singlecolumn
			grid-template-columns: 1fr
			flex-direction: column

			.videoPreview-item
				width: 75vh
				margin: 0 auto 2em

		@supports (display: grid)
			display: grid
			grid-template-columns: repeat(3, 1fr)

	&-item
		position: relative
		width: 33.33%
		cursor: pointer
		border: none
		background-color: transparent

		&.is-hidden
			display: none

		@supports (display: grid)
			width: initial

	&-spacer
		display: block
		padding-top: (9 / 16) * 100%
		width: 100%

	&-cover
		+absolute(0 0 0 0)
		justify-items: center
		+size(100%)
		text-decoration: none
		background-color: rgba(black, 0)
		transition: background-color 300ms

		@supports (display: grid)
			display: grid
			grid-template-rows: 1fr 70px 1fr

	&-play
		opacity: 0
		transition: opacity 300ms

	&-info
		text-align: center
		color: $c-text-invert
		display: flex
		flex-direction: column
		justify-content: center
		opacity: 0
		transition: opacity 150ms

	&-title
		font-weight: $fw-bold
		font-size: 17px
		color: #f4f4f4
		margin: 0

	&-image
		+size(100%, 100%)
		+absolute(0 0 0 0)
		object-fit: cover
		// padding-top: (270 / 480) * 100%

	&-item:hover
		.videoPreview-cover
			background-color: rgba(black, .45)
		.videoPreview-info
			opacity: 1
		.videoPreview-play
			opacity: 1

	@media #{$break768M}
		&-container
			flex-direction: column
			grid-template-columns: 1fr

		&-info
			opacity: 1
		&-play
			opacity: 1
		&-item::after
			opacity: 0
