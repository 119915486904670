.contactTabs
	max-width: 1000px
	margin: 4em auto

	&-tabs
		display: flex
		border-bottom: 1px solid $c-grey

		+media(400M)
			overflow-x: auto
			overflow-y: hidden

	&-tab
		+relative
		text-align: center
		flex: 1 0 0
		padding: 1em
		text-decoration: none
		border: none
		background: transparent
		cursor: pointer
		border-right: 1px solid $c-grey

		&:last-child
			border-right: none

		&.is-active

			+media(400M)
				background: rgba(0, 0, 0, .1)

			&::after
				content: ''
				display: block
				+size(0)
				border: 20px solid $c-grey
				border-right-color: transparent
				border-left-color: transparent
				border-bottom-color: transparent
				+absolute(null 0 0 0)
				transform: translateY(100%)
				margin: 0 auto

	&-city
		margin: 0 0 .6em

	&-country
		margin: 0
		// font-weight: normal

	&-contents
		display: none
		&.is-active
			display: flex
	&-content
		display: flex
		flex-direction: column
		width: 100%

		+media(700px)
			flex-direction: row

		&.is-hidden
			display: none

	&-column
		flex-basis: 0
		flex-grow: 1
		padding: 1em
		padding-top: 6em

	&-place
		order: -1
		+media(700px)
			order: 1
			display: inline-flex
			justify-content: center
