.contactPreview
	display: flex
	flex-direction: column
	align-items: center
	background: $c-text-invert-back
	padding: 89px 1em 70px

	&-title
		color: white
		font-size: 16px
		line-height: 1.4
		font-weight: 700
		letter-spacing: 4.25px
		text-transform: uppercase
		margin: 0 0 42px
		text-align: center

		+media(700px)
			font-size: 48px

.view-hidden
	display: none
