.profile
	display: flex
	flex-direction: column
	align-items: center
	text-decoration: none
	width: 117px

	&-profile
		display: none
		&.is-active
			display: flex

	&-photo
		+size(85px)
		border-radius: 50%
		margin: 14px 16px
		object-fit: cover
		overflow: hidden
		position: relative

	&-name
		text-align: center
		color: #1c1c1c
		font-size: 16px
		font-weight: 700
		letter-spacing: 0.25px
		margin-bottom: 0

	// For "contact us" page
	&-infoline
		color: #696969
		font-size: 16px
		line-height: 1.6
		text-align: center

		&.view-small
			font-size: 14px
			line-height: 1.3

		&.view-nowrap
			white-space: nowrap

		&.phone
			text-decoration: none

		&.position
			margin-bottom: 0

	// For director detail page
	&.view-large
		margin: 27px .5em 0
		width: auto

		.profile-name
			font-size: 48px
			letter-spacing: 4.25px
			margin: 0 0 27px

	&.view-medium
		width: 146px

	&.tabletop-profile
		margin-top: 0
