.shape
	display: inline-block
	vertical-align: middle
	line-height: inherit

	&-defs
		+size(0)
		+absolute
		visibility: hidden
		overflow: hidden

	// Shapes default dimensions
	&-logo
		+size(157px, 27px)
		fill: #1c1c1c

		&.view-white
			fill: white

		&.view-medium
			+size(218px, 40px)

		&.view-big
			+size(543px, 100px)

	&-chevron
		+size(26px, 16px)

	&-play
		+size(70px)

	&-facebook,
	&-instagram
		+size(25px)

	&-mangoweb
		+size(95px, 30px)
		fill: white

	&-location
		+size(14px, 19px)

	// ... here comes other shape dimensions

	// Fill parent view
	&.view-fill
		+size(100%)
		display: block

	// Position and center shape absolutely
	&.view-abs
		+absolute
		margin: auto

	// Mirrored views
	&.view-180deg
		transform: rotate(180deg)

	&.view-90deg
		transform: rotate(90deg)

	&.view-270deg
		transform: rotate(270deg)

	&-cross
		+size(1rem, 1rem)
