@keyframes fade-in
	from
		opacity: 0
	to
		opacity: 1

@keyframes fade-in-background
	from
		background-color: rgba(black, 0)
	to
		background-color: rgba(black, .7)

.videoPlayer
	+fixed(0 0 0 0)
	background-color: rgba(black, .7)
	display: flex
	align-items: center
	justify-content: center
	z-index: 100
	animation: fade-in-background 600ms ease-out
	animation-fill-mode: both

	&-inner
		width: 90vw
		max-width: 1000px
		padding: 1em
		background-color: white
		position: relative
		animation: fade-in 600ms ease-out
		animation-delay: 300ms
		animation-fill-mode: both

		&::before
			content: ''
			padding-top: 9/16*100%
			padding-top: calc(var(--aspect-ratio, calc(9 / 16)) * 100%)
			display: block

		&::after
			content: '×'
			+absolute(-3.1rem -1.3rem null null)
			color: white
			font-size: 47px

	&-player
		width: 100%
		height: 100%
		+absolute(0 0 0 0)
		padding: 1em
		border: none
		background-color: black
		background-clip: content-box
		z-index: 1000

