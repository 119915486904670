.tabs

	&-bar
		display: flex
		margin: 1em 0
		border-bottom: 2px solid #dcdcdc
		overflow: auto
		+media(700)
			justify-content: center
			overflow: initial

	&-item
		opacity: 0.3
		color: #1c1c1c
		font-weight: bold
		letter-spacing: 1.16px
		text-transform: uppercase
		position: relative
		padding: 11px 0
		transition: all 300ms ease-out
		text-decoration: none
		text-align: center
		margin: 0 20px
		font-size: 17px
		border: none
		background: transparent
		cursor: pointer
		+media(700)
			margin: 0 52px
			font-size: 22px

		+media(700)
			&::after
				content: ''
				width: 100%
				height: 5px
				background-color: #1c1c1c
				+absolute(null 0 -2.5px 0)
				transform: scaleX(0)
				transition: all 300ms ease-out

		&:hover
			opacity: .7

		&.is-active
			opacity: 1
			&::after
				transform: scaleX(1)

	&-content
		width: 100vw
		display: none
		&.is-active
			display: block
