.typo
	&-heading
		color: #1c1c1c
		font-size: 16px
		line-height: 1.4
		font-weight: 700
		letter-spacing: 4.25px
		text-transform: uppercase
		margin: 0 0 34px
		text-align: center
		word-break: break-word

		+media(700px)
			font-size: 48px

	&-perex
		max-width: 904px
		color: #626262
		font-size: 19px
		font-weight: 400
		letter-spacing: 0.52px
		line-height: 1.32
		margin: 0 auto 54px
		text-align: center
		padding: 0 1em
		b
			font-weight: 700
			color: #000

		i
			font-style: italic

		&.view-smallMargin
			margin-bottom: 22px

		&.tabletop-perex
			margin-bottom: 0
