// Favorite constants
$gratio: 1.618
$pi: 3.14159

// Dimensions
$radius: 2px
$spacing: 24px
$padding: 10px

// Font properties
$font-family-sans-serif: brandon-grotesque, 'Helvetica Neue', Helvetica, Arial, sans-serif
$font-family-serif: Georgia, Cambria, 'Times New Roman', Times, serif
$font-family-monospace: Monaco, Menlo, Consolas, 'Courier New', monospace

$font-family-base: $font-family-sans-serif
$font-family-head: $font-family-sans-serif

$base-font-size: 16px
$fw-light: 300
$fw-normal: 400
$fw-medium: 500
$fw-semibold: 600
$fw-bold: 700
$em-base: $base-font-size

// Colors
$c-main: black
$c-text-back: white
$c-comp: white
$c-alt: #000

$c-text: black
$c-text-light: #626262
$c-grey: #dcdcdc

$c-text-invert: white
$c-text-invert-back: #1c1c1c

// Breakpoints
$break320: '(min-width: 320px)'
$break480: '(min-width: 480px)'
$break768: '(min-width: 768px)'
$break992: '(min-width: 992px)'
$break1200: '(min-width: 1200px)'

$break320M: '(max-width: 319px)'
$break480M: '(max-width: 479px)'
$break768M: '(max-width: 767px)'
$break992M: '(max-width: 991px)'
$break1200M: '(max-width: 1199px)'

$landscape: '(orientation: landscape)'
$portrait: '(orientation: portrait)'

