.nav
	display: flex
	padding: 18px
	flex-direction: column
	white-space: nowrap

	&-logo
		display: none
		padding: 6px 0

	&-center
		display: flex
		justify-content: center

	&-item
		display: block
		margin: 0 21px
		color: $c-text
		text-decoration: none
		letter-spacing: 0.27px
		color: #7c7c7c
		padding: .5em

		&.is-active
			color: #1c1c1c
			font-weight: 700

		&:hover
			color: #1c1c1c

	&-right
		opacity: 0.5
		color: #1c1c1c
		text-decoration: none

	&-menu
		display: none
		text-align: center

	&-trigger
		text-align: center

	&-checkbox
		display: none

		&:checked
			& ~ .nav-menu,
			& ~ * .nav-center,
			& ~ * .nav-right
				display: flex
				flex-direction: column

			& ~ .nav-menu
				background: rgba(255,255,255,.9)
				+fixed(0 0 0 0)
				z-index: 100
				justify-content: center
				padding-top: 2em

			.nav & ~ * .nav-item
				color: black
				font-size: 2em
				@media (max-height: 550px)
					font-size: 1em

			& ~ .nav-trigger
				&::after
					content: 'Close menu'
					+fixed(0 0 null 0)
					z-index: 101
					font-size: 1.5em
					padding: .5em
					color: #666

	&.view-overlay
		+absolute(0px 0px null)

		.nav-item
			color: $c-text-invert

		.nav-right
			color: white

	@media #{$break992M}
		&-item
			margin: 0 11px

	@media #{$break768}

		@supports (display: grid)
			display: grid
			grid-template-columns: 1fr 6fr
			justify-content: center

		&-logo
			display: block

		&-trigger
			display: none

		&-menu
			display: flex

			@supports (display: grid)
				display: grid
				grid-template-columns: 5fr 1fr

		&-right
			text-align: right

		&-home
			display: none

