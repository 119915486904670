/*! Default styles from your beloved manGoweb (http://mangoweb.cz) */

*, *:before, *:after
	box-sizing: border-box

html
	font-size: $base-font-size

body
	background: $c-text-back
	color: $c-text
	font-family: $font-family-base
	font-size: $base-font-size
	line-height: $gratio
	overflow-x: hidden

a
	color: $c-main
	text-decoration: underline

h1, h2, h3, h4, h5, h6
	font-family: $font-family-head
	line-height: 1
	margin: $spacing*2 0 1em
	text-rendering: optimizeLegibility

img
	max-width: 100%
	height: auto
	vertical-align: middle

p
	margin: 0 0 $spacing

	img
		margin-bottom: $spacing

ol, ul, li
	margin: 0
	padding: 0

blockquote
	margin: $spacing 0
	padding: $padding 0 $padding $spacing
	border-left: 2px solid
	color: $c-text-light

table
	width: 100%

input,
button,
select,
textarea
	font-family: inherit
	font-size: inherit
	line-height: inherit

svg
	pointer-events: none

b, i
	font-style: normal
	font-weight: normal

