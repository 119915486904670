.seeWorks
	border-top: 1px solid #e8e8e8
	background-color: #f6f6f6
	display: flex
	flex-direction: column
	align-items: center
	padding: 75px 1em 49px

	&-title
		color: #1c1c1c
		font-size: 48px
		font-weight: 700
		letter-spacing: 4.25px
		text-transform: uppercase
		margin: 0 0 52px
		text-align: center
